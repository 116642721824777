import { dateFun } from "../../../common-utilities/utils";
import { monthNumberMap } from "../../../common-utilities/utils/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export const getElectricityTableHeader = () => {
  const headers = [
    {
      label: "Store",
      id: "store",
      render: (data) => {
        return <div>{data?.storeId?.name}</div>;
      },
    },

    { label: "Last Working Day", id: "monthLastWorkingDay" },
    {
      label: "Month",
      id: "monthName",
      render: (data) => {
        return monthNumberMap[data.month];
      },
    },

    { label: "Year", id: "year" },
    {
      label: "Daily Readings",
      id: "dailyReading",
      render: (data, onClick) => {
        return (
          <RemoveRedEyeIcon
            onClick={() => onClick(data, "VIEW_READINGS")}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    { label: "Total Month Consumption", id: "totalMonthConsumption" },
  ];

  return headers;
};
