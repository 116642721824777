import { isValidEmail } from "../../../common-utilities/utils";
import { INDIA_STATE_LIST } from "../../../common-utilities/utils/constants";
import { VENDOR_DOCUMENT_TYPES } from "../vendor-request-details/helper";

export const PHONE_NO_REG_EX = /^\d{10}$/;
export const ALPHA_NUMERIC = /^[a-zA-Z0-9_.-]*$/;
export const PAN_CARD_REG_EX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}/;
export const isFormValid = (formDetails) => {
  const {
    nameOfLegalEntity,
    email,
    mobile = "",
    gstStatus = "",
    gst = "",
    pan = "",
    cancelledCheque = "",
    focalPointOfContact = "",
    ifsc = "",
    bankName = "",
    line = "",
    city = "",
    pincode = "",
    country = "",
    accountNumber = "",
    panDoc = "",
    typeOfVendor = "",
    creditPeriod = "",
    vendorTenure = "",
    msme = "",
    msmeDoc = "",
    gstDoc = "",
  } = formDetails;

  let error = {};

  if (!isValidEmail(email)) {
    error.email = true;
  }

  if (!mobile || !mobile.match(PHONE_NO_REG_EX)) {
    error.mobile = true;
  }

  if (!ifsc || !ifsc.match(ALPHA_NUMERIC) || ifsc?.length != 11) {
    error.ifsc = true;
  }

  if (!pincode || !pincode.match(ALPHA_NUMERIC)) {
    error.pincode = true;
  }

  if (!bankName) {
    error.bankName = true;
  }
  if (!creditPeriod) {
    error.creditPeriod = true;
  }
  if (!nameOfLegalEntity) {
    error.nameOfLegalEntity = true;
  }

  if (!line) {
    error.line = true;
  }

  if (!city || !city.match(ALPHA_NUMERIC)) {
    error.city = true;
  }

  if (!country || !country.match(ALPHA_NUMERIC)) {
    error.country = true;
  }

  if (gstStatus === "Yes" && gst.length !== 15) {
    error.gst = true;
  }
  if (gstStatus === "Yes" && !gstDoc) {
    error.gstDoc = true;
  }
  if (
    !pan ||
    !pan.match(ALPHA_NUMERIC) ||
    pan.length !== 10 ||
    !pan.match(PAN_CARD_REG_EX)
  ) {
    error.pan = true;
  }

  if (!focalPointOfContact) {
    error.focalPointOfContact = true;
  }
  if (!typeOfVendor) {
    error.typeOfVendor = true;
  }
  if (!vendorTenure) {
    error.vendorTenure = true;
  }
  if (!cancelledCheque) {
    error.cancelledCheque = true;
  }

  if (!panDoc) {
    error.panDoc = true;
  }
  if (msme === "Yes" && !msmeDoc) {
    error.msmeDoc = true;
  }
  if (!accountNumber) {
    error.accountNumber = true;
  }
  return error;
};

export const NATURE_OF_COMPLAINT = [
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "Supplies",
    label: "Supplies",
  },
];

export const GST_STATUS = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const APPROVED_BANK_LIST = [
  {
    id: 1,
    label: "Axis Bank Ltd.",
    value: "Axis Bank Ltd.",
  },
  {
    id: 2,
    label: "Bandhan Bank Ltd.",
    value: "Bandhan Bank Ltd.",
  },
  {
    id: 3,
    label: "CSB Bank Limited",
    value: "CSB Bank Limited",
  },
  {
    id: 4,
    label: "City Union Bank Ltd.",
    value: "City Union Bank Ltd.",
  },
  {
    id: 5,
    label: "DCB Bank Ltd.",
    value: "DCB Bank Ltd.",
  },
  {
    id: 6,
    label: "Dhanlaxmi Bank Ltd.",
    value: "Dhanlaxmi Bank Ltd.",
  },
  {
    id: 7,
    label: "Federal Bank Ltd.",
    value: "Federal Bank Ltd.",
  },
  {
    id: 8,
    label: "HDFC Bank Ltd",
    value: "HDFC Bank Ltd",
  },
  {
    id: 9,
    label: "ICICI Bank Ltd.",
    value: "ICICI Bank Ltd.",
  },
  {
    id: 10,
    label: "IndusInd Bank Ltd",
    value: "IndusInd Bank Ltd",
  },
  {
    id: 11,
    label: "IDFC FIRST Bank Limited",
    value: "IDFC FIRST Bank Limited",
  },
  {
    id: 12,
    label: "Jammu & Kashmir Bank Ltd.",
    value: "Jammu & Kashmir Bank Ltd.",
  },
  {
    id: 13,
    label: "Karnataka Bank Ltd.",
    value: "Karnataka Bank Ltd.",
  },
  {
    id: 14,
    label: "Karur Vysya Bank Ltd.",
    value: "Karur Vysya Bank Ltd.",
  },
  {
    id: 15,
    label: "Kotak Mahindra Bank Ltd",
    value: "Kotak Mahindra Bank Ltd",
  },
  {
    id: 16,
    label: "Nainital bank Ltd.",
    value: "Nainital bank Ltd.",
  },
  {
    id: 17,
    label: "RBL Bank Ltd.",
    value: "RBL Bank Ltd.",
  },
  {
    id: 18,
    label: "South Indian Bank Ltd.",
    value: "South Indian Bank Ltd.",
  },
  {
    id: 19,
    label: "Tamilnad Mercantile Bank Ltd.",
    value: "Tamilnad Mercantile Bank Ltd.",
  },
  {
    id: 20,
    label: "YES Bank Ltd.",
    value: "YES Bank Ltd.",
  },
  {
    id: 21,
    label: "IDBI Bank Limited",
    value: "IDBI Bank Limited",
  },
  {
    id: 22,
    label: "Coastal Local Area Bank Ltd",
    value: "Coastal Local Area Bank Ltd",
  },
  {
    id: 23,
    label: "Krishna Bhima Samruddhi LAB Ltd",
    value: "Krishna Bhima Samruddhi LAB Ltd",
  },
  {
    id: 24,
    label: "Au Small Finance Bank Ltd.",
    value: "Au Small Finance Bank Ltd.",
  },
  {
    id: 25,
    label: "Capital Small Finance Bank Ltd",
    value: "Capital Small Finance Bank Ltd",
  },
  {
    id: 26,
    label: "Fincare Small Finance Bank Ltd.",
    value: "Fincare Small Finance Bank Ltd.",
  },
  {
    id: 27,
    label: "Equitas Small Finance Bank Ltd",
    value: "Equitas Small Finance Bank Ltd",
  },
  {
    id: 28,
    label: "ESAF Small Finance Bank Ltd.",
    value: "ESAF Small Finance Bank Ltd.",
  },
  {
    id: 29,
    label: "Suryoday Small Finance Bank Ltd.",
    value: "Suryoday Small Finance Bank Ltd.",
  },
  {
    id: 30,
    label: "Ujjivan Small Finance Bank Ltd.",
    value: "Ujjivan Small Finance Bank Ltd.",
  },
  {
    id: 31,
    label: "Utkarsh Small Finance Bank Ltd.",
    value: "Utkarsh Small Finance Bank Ltd.",
  },
  {
    id: 32,
    label: "North East Small finance Bank Ltd",
    value: "North East Small finance Bank Ltd",
  },
  {
    id: 33,
    label: "Jana Small Finance Bank Ltd",
    value: "Jana Small Finance Bank Ltd",
  },
  {
    id: 34,
    label: "Shivalik Small Finance Bank Ltd",
    value: "Shivalik Small Finance Bank Ltd",
  },
  {
    id: 35,
    label: "Unity Small Finance Bank Ltd",
    value: "Unity Small Finance Bank Ltd",
  },
  {
    id: 36,
    label: "Airtel Payments Bank Ltd",
    value: "Airtel Payments Bank Ltd",
  },
  {
    id: 37,
    label: "India Post Payments Bank Ltd",
    value: "India Post Payments Bank Ltd",
  },
  {
    id: 38,
    label: "FINO Payments Bank Ltd",
    value: "FINO Payments Bank Ltd",
  },
  {
    id: 39,
    label: "Paytm Payments Bank Ltd",
    value: "Paytm Payments Bank Ltd",
  },
  {
    id: 40,
    label: "Jio Payments Bank Ltd",
    value: "Jio Payments Bank Ltd",
  },
  {
    id: 41,
    label: "NSDL Payments Bank Limited",
    value: "NSDL Payments Bank Limited",
  },
  {
    id: 42,
    label: "Bank of Baroda",
    value: "Bank of Baroda",
  },
  {
    id: 43,
    label: "Bank of India",
    value: "Bank of India",
  },
  {
    id: 44,
    label: "Bank of Maharashtra",
    value: "Bank of Maharashtra",
  },
  {
    id: 45,
    label: "Canara Bank",
    value: "Canara Bank",
  },
  {
    id: 46,
    label: "Central Bank of India",
    value: "Central Bank of India",
  },
  {
    id: 47,
    label: "Indian Bank",
    value: "Indian Bank",
  },
  {
    id: 48,
    label: "Indian Overseas Bank",
    value: "Indian Overseas Bank",
  },
  {
    id: 49,
    label: "Punjab & Sind Bank",
    value: "Punjab & Sind Bank",
  },
  {
    id: 50,
    label: "Punjab National Bank",
    value: "Punjab National Bank",
  },
  {
    id: 51,
    label: "State Bank of India",
    value: "State Bank of India",
  },
  {
    id: 52,
    label: "UCO Bank",
    value: "UCO Bank",
  },
  {
    id: 53,
    label: "Union Bank of India",
    value: "Union Bank of India",
  },
  {
    id: 54,
    label: "National Bank for Agriculture and Rural Development",
    value: "National Bank for Agriculture and Rural Development",
  },
  {
    id: 55,
    label: "Export-Import Bank of India",
    value: "Export-Import Bank of India",
  },
  {
    id: 56,
    label: "National Housing Bank",
    value: "National Housing Bank",
  },
  {
    id: 57,
    label: "Small Industries Development Bank of India",
    value: "Small Industries Development Bank of India",
  },
  {
    id: 58,
    label: "Assam Gramin Vikash Bank",
    value: "Assam Gramin Vikash Bank",
  },
  {
    id: 59,
    label: "Andhra Pradesh Grameena Vikas Bank",
    value: "Andhra Pradesh Grameena Vikas Bank",
  },
  {
    id: 60,
    label: "Andhra Pragathi Grameena Bank",
    value: "Andhra Pragathi Grameena Bank",
  },
  {
    id: 61,
    label: "Arunachal Pradesh Rural Bank",
    value: "Arunachal Pradesh Rural Bank",
  },
  {
    id: 62,
    label: "Aryavart Bank",
    value: "Aryavart Bank",
  },
  {
    id: 63,
    label: "Bangiya Gramin Vikash Bank",
    value: "Bangiya Gramin Vikash Bank",
  },
  {
    id: 64,
    label: "Baroda Gujarat Gramin Bank",
    value: "Baroda Gujarat Gramin Bank",
  },
  {
    id: 65,
    label: "Baroda Rajasthan Kshetriya Gramin Bank",
    value: "Baroda Rajasthan Kshetriya Gramin Bank",
  },
  {
    id: 66,
    label: "Baroda UP Bank",
    value: "Baroda UP Bank",
  },
  {
    id: 67,
    label: "Chaitanya Godavari GB",
    value: "Chaitanya Godavari GB",
  },
  {
    id: 68,
    label: "Chhattisgarh Rajya Gramin Bank",
    value: "Chhattisgarh Rajya Gramin Bank",
  },
  {
    id: 69,
    label: "Dakshin Bihar Gramin Bank",
    value: "Dakshin Bihar Gramin Bank",
  },
  {
    id: 70,
    label: "Ellaquai Dehati Bank",
    value: "Ellaquai Dehati Bank",
  },
  {
    id: 71,
    label: "Himachal Pradesh Gramin Bank",
    value: "Himachal Pradesh Gramin Bank",
  },
  {
    id: 72,
    label: "J&K Grameen Bank",
    value: "J&K Grameen Bank",
  },
  {
    id: 73,
    label: "Jharkhand Rajya Gramin Bank",
    value: "Jharkhand Rajya Gramin Bank",
  },
  {
    id: 74,
    label: "Karnataka Gramin Bank",
    value: "Karnataka Gramin Bank",
  },
  {
    id: 75,
    label: "Karnataka Vikas Gramin Bank",
    value: "Karnataka Vikas Gramin Bank",
  },
  {
    id: 76,
    label: "Kerala Gramin Bank",
    value: "Kerala Gramin Bank",
  },
  {
    id: 77,
    label: "Madhya Pradesh Gramin Bank",
    value: "Madhya Pradesh Gramin Bank",
  },
  {
    id: 78,
    label: "Madhyanchal Gramin Bank",
    value: "Madhyanchal Gramin Bank",
  },
  {
    id: 79,
    label: "Maharashtra Gramin Bank",
    value: "Maharashtra Gramin Bank",
  },
  {
    id: 80,
    label: "Manipur Rural Bank",
    value: "Manipur Rural Bank",
  },
  {
    id: 81,
    label: "Meghalaya Rural Bank",
    value: "Meghalaya Rural Bank",
  },
  {
    id: 82,
    label: "Mizoram Rural Bank",
    value: "Mizoram Rural Bank",
  },
  {
    id: 83,
    label: "Nagaland Rural Bank",
    value: "Nagaland Rural Bank",
  },
  {
    id: 84,
    label: "Odisha Gramya Bank",
    value: "Odisha Gramya Bank",
  },
  {
    id: 85,
    label: "Paschim Banga Gramin Bank",
    value: "Paschim Banga Gramin Bank",
  },
  {
    id: 86,
    label: "Prathama U.P. Gramin Bank",
    value: "Prathama U.P. Gramin Bank",
  },
  {
    id: 87,
    label: "Puduvai Bharathiar Grama Bank",
    value: "Puduvai Bharathiar Grama Bank",
  },
  {
    id: 88,
    label: "Punjab Gramin Bank",
    value: "Punjab Gramin Bank",
  },
  {
    id: 89,
    label: "Rajasthan Marudhara Gramin Bank",
    value: "Rajasthan Marudhara Gramin Bank",
  },
  {
    id: 90,
    label: "Saptagiri Grameena Bank",
    value: "Saptagiri Grameena Bank",
  },
  {
    id: 91,
    label: "Sarva Haryana Gramin Bank",
    value: "Sarva Haryana Gramin Bank",
  },
  {
    id: 92,
    label: "Saurashtra Gramin Bank",
    value: "Saurashtra Gramin Bank",
  },
  {
    id: 93,
    label: "Tamil Nadu Grama Bank",
    value: "Tamil Nadu Grama Bank",
  },
  {
    id: 94,
    label: "Telangana Grameena Bank",
    value: "Telangana Grameena Bank",
  },
  {
    id: 95,
    label: "Tripura Gramin Bank",
    value: "Tripura Gramin Bank",
  },
  {
    id: 96,
    label: "Uttar Bihar Gramin Bank",
    value: "Uttar Bihar Gramin Bank",
  },
  {
    id: 97,
    label: "Utkal Grameen Bank",
    value: "Utkal Grameen Bank",
  },
  {
    id: 98,
    label: "Uttarbanga Kshetriya Gramin Bank",
    value: "Uttarbanga Kshetriya Gramin Bank",
  },
  {
    id: 99,
    label: "Vidharbha Konkan Gramin Bank",
    value: "Vidharbha Konkan Gramin Bank",
  },
  {
    id: 100,
    label: "Uttarakhand Gramin Bank",
    value: "Uttarakhand Gramin Bank",
  },
  {
    id: 101,
    label: "AB Bank Ltd.",
    value: "AB Bank Ltd.",
  },
  {
    id: 102,
    label: "Abu Dhabi Commercial Bank PJSC",
    value: "Abu Dhabi Commercial Bank PJSC",
  },
  {
    id: 103,
    label: "American Express Banking Corporation",
    value: "American Express Banking Corporation",
  },
  {
    id: 104,
    label: "Australia and New Zealand Banking Group Ltd.",
    value: "Australia and New Zealand Banking Group Ltd.",
  },
  {
    id: 105,
    label: "Barclays Bank Plc.",
    value: "Barclays Bank Plc.",
  },
  {
    id: 106,
    label: "Bank of America",
    value: "Bank of America",
  },
  {
    id: 107,
    label: "Bank of Bahrain & Kuwait B.S.C.",
    value: "Bank of Bahrain & Kuwait B.S.C.",
  },
  {
    id: 108,
    label: "Bank of Ceylon",
    value: "Bank of Ceylon",
  },
  {
    id: 109,
    label: "Bank of China",
    value: "Bank of China",
  },
  {
    id: 110,
    label: "Bank of Nova Scotia",
    value: "Bank of Nova Scotia",
  },
  {
    id: 111,
    label: "BNP Paribas",
    value: "BNP Paribas",
  },
  {
    id: 112,
    label: "Citibank N.A.",
    value: "Citibank N.A.",
  },
  {
    id: 113,
    label:
      "Cooperatieve Rabobank U.A./ Coöperatieve Centrale Raiffeisen-Boerenleenbank B.A.",
    value:
      "Cooperatieve Rabobank U.A./ Coöperatieve Centrale Raiffeisen-Boerenleenbank B.A.",
  },
  {
    id: 114,
    label: "Credit Agricole Corporate & Investment Bank",
    value: "Credit Agricole Corporate & Investment Bank",
  },
  {
    id: 115,
    label: "Credit Suisse AG",
    value: "Credit Suisse AG",
  },
  {
    id: 116,
    label: "CTBC Bank Co., Ltd.",
    value: "CTBC Bank Co., Ltd.",
  },
  {
    id: 117,
    label: "DBS Bank India Limited",
    value: "DBS Bank India Limited",
  },
  {
    id: 118,
    label: "Deutsche Bank A.G.",
    value: "Deutsche Bank A.G.",
  },
  {
    id: 119,
    label: "Doha Bank Q.P.S.C",
    value: "Doha Bank Q.P.S.C",
  },
  {
    id: 120,
    label: "Emirates NBD Bank PJSC",
    value: "Emirates NBD Bank PJSC",
  },
  {
    id: 121,
    label: "First Abu Dhabi Bank PJSC",
    value: "First Abu Dhabi Bank PJSC",
  },
  {
    id: 122,
    label: "FirstRand Bank Limited",
    value: "FirstRand Bank Limited",
  },
  {
    id: 123,
    label: "Hong Kong and Shanghai Banking Corporation Limited",
    value: "Hong Kong and Shanghai Banking Corporation Limited",
  },
  {
    id: 124,
    label: "Industrial & Commercial Bank of China Ltd.",
    value: "Industrial & Commercial Bank of China Ltd.",
  },
  {
    id: 125,
    label: "Industrial Bank of Korea",
    value: "Industrial Bank of Korea",
  },
  {
    id: 126,
    label: "J.P. Morgan Chase Bank N.A.",
    value: "J.P. Morgan Chase Bank N.A.",
  },
  {
    id: 127,
    label: "JSC VTB Bank",
    value: "JSC VTB Bank",
  },
  {
    id: 128,
    label: "KEB Hana Bank",
    value: "KEB Hana Bank",
  },
  {
    id: 129,
    label: "Kookmin Bank",
    value: "Kookmin Bank",
  },
  {
    id: 130,
    label: "Krung Thai Bank Public Co. Ltd. $",
    value: "Krung Thai Bank Public Co. Ltd. $",
  },
  {
    id: 131,
    label: "Mashreq bank PSC",
    value: "Mashreq bank PSC",
  },
  {
    id: 132,
    label: "Mizuho Bank Ltd.",
    value: "Mizuho Bank Ltd.",
  },
  {
    id: 133,
    label: "MUFG Bank, Ltd.",
    value: "MUFG Bank, Ltd.",
  },
  {
    id: 134,
    label: "NatWest Markets Plc",
    value: "NatWest Markets Plc",
  },
  {
    id: 135,
    label: "PT Bank Maybank Indonesia TBK",
    value: "PT Bank Maybank Indonesia TBK",
  },
  {
    id: 136,
    label: "Qatar National Bank (Q.P.S.C.)",
    value: "Qatar National Bank (Q.P.S.C.)",
  },
  {
    id: 137,
    label: "Sberbank",
    value: "Sberbank",
  },
  {
    id: 138,
    label: "SBM Bank (India) Limited",
    value: "SBM Bank (India) Limited",
  },
  {
    id: 139,
    label: "Shinhan Bank",
    value: "Shinhan Bank",
  },
  {
    id: 140,
    label: "Societe Generale India",
    value: "Societe Generale India",
  },
  {
    id: 141,
    label: "Sonali Bank Ltd. %",
    value: "Sonali Bank Ltd. %",
  },
  {
    id: 142,
    label: "Standard Chartered Bank",
    value: "Standard Chartered Bank",
  },
  {
    id: 143,
    label: "Sumitomo Mitsui Banking Corporation",
    value: "Sumitomo Mitsui Banking Corporation",
  },
  {
    id: 144,
    label: "United Overseas Bank Limited",
    value: "United Overseas Bank Limited",
  },
  {
    id: 145,
    label: "Woori Bank",
    value: "Woori Bank",
  },
  {
    id: 146,
    label: "Saraswat Co-operative Bank Ltd.",
    value: "Saraswat Co-operative Bank Ltd.",
  },
  {
    id: 147,
    label: "The Cosmos Co-operative Bank Ltd",
    value: "The Cosmos Co-operative Bank Ltd",
  },
  {
    id: 148,
    label: "The Maharashtra State Co-operative Bank Ltd",
    value: "The Maharashtra State Co-operative Bank Ltd",
  },
  {
    id: 149,
    label: "The Kalupur commercial co-op bank Ltd",
    value: "The Kalupur commercial co-op bank Ltd",
  },
  {
    id: 150,
    label: "Vasai Janata Sahakari Bank Ltd",
    value: "Vasai Janata Sahakari Bank Ltd",
  },
  {
    id: 151,
    label: "NKGSB Co-operative Bank Ltd",
    value: "NKGSB Co-operative Bank Ltd",
  },
  {
    id: 152,
    label: "The Surat District Co-Op. Bank Ltd.",
    value: "The Surat District Co-Op. Bank Ltd.",
  },
  {
    id: 153,
    label: "SVC Co-Operative Bank Ltd",
    value: "SVC Co-Operative Bank Ltd",
  },
  {
    id: 154,
    label: "Citizencredit Co-op Bank Ltd",
    value: "Citizencredit Co-op Bank Ltd",
  },
  {
    id: 155,
    label: "TJSB Sahakari Bank Ltd",
    value: "TJSB Sahakari Bank Ltd",
  },
  {
    id: 156,
    label: "Janaseva Sahakari Bank Ltd",
    value: "Janaseva Sahakari Bank Ltd",
  },
  {
    id: 157,
    label: "Bharat Co-operative Bank (Mumbai) Ltd",
    value: "Bharat Co-operative Bank (Mumbai) Ltd",
  },
  {
    id: 158,
    label: "The Kalyan Janata Sahakari Bank Ltd.",
    value: "The Kalyan Janata Sahakari Bank Ltd.",
  },
];

export const agreementAvailableOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const formatPayload = (formDetails) => {
  const {
    nameOfLegalEntity,
    email,
    mobile = "",
    alternativeMobile,
    gstStatus = "",
    gst = "",
    pan = "",
    gstDoc = {},
    panDoc = {},
    cancelledCheque = "",
    focalPointOfContact = "",
    typeOfVendor,
    msme,
    msmeNumber,
    formId,
    accountNumber,
    bankName = "",
    ifsc,
    line,
    street,
    city,
    country,
    pincode,
    msmeDoc = {},
    state = {},
    msmeType,
    department,
    creditPeriod,
    attachment = "",
    agreementAvailable,
    vendorTenure,
  } = formDetails;
  const attachments = [];
  if (gstDoc.url) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.GST_DOC,
      url: gstDoc.url,
    });
  }
  if (attachment?.url) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.ATTACHMENT,
      url: attachment.url,
    });
  }

  if (panDoc.url) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.PAN_CARD,
      url: panDoc.url,
    });
  }

  if (msmeDoc.url) {
    attachments.push({
      type: VENDOR_DOCUMENT_TYPES.MSME_DOC,
      url: msmeDoc.url,
    });
  }

  const payload = {
    formId: formId,
    email: email,
    bankDetails: {
      accountNumber: accountNumber,
      bankName: bankName,
      ifsc: ifsc,
    },
    address: {
      line: line,
      street: street,
      city: city,
      state: state.value,
      country: country,
      pincode: pincode,
    },
    nameOfLegalEntity: nameOfLegalEntity,
    pan: pan,
    focalPointOfContact: focalPointOfContact,
    mobile: mobile,
    gstStatus: gstStatus,
    gst: gst,
    msme: msme,
    msmeNumber: msmeNumber,
    msmeType: msmeType,
    attachments: attachments,
    typeOfVendor: typeOfVendor,
    department: department,
    cancelledCheque: cancelledCheque.url,
    vendorTenure: vendorTenure,
  };
  if (agreementAvailable) {
    payload.agreementAvailable = agreementAvailable.value;
  }
  if (alternativeMobile) {
    payload.alternativeMobile = alternativeMobile;
  }
  if (creditPeriod || typeof creditPeriod === "number") {
    payload.creditPeriod = creditPeriod;
  } else {
    payload.creditPeriod = null;
  }

  return payload;
};

export const formateSubmittedDetails = (submittedDetails) => {
  const {
    cancelledCheque = "",
    attachments = [],
    address = {},
    bankDetails,
  } = submittedDetails;
  let details = {
    ...submittedDetails,
    ...address,
    ...bankDetails,
    state: INDIA_STATE_LIST.find((s) => s.value === address.state) || "",
  };
  if (cancelledCheque) {
    details.cancelledCheque = {
      url: cancelledCheque,
      blobName: "Cancled Cheque",
    };
  }
  if (attachments.length > 0) {
    const gstDoc = attachments.find((a) => a.type === "GST_DOC");
    if (gstDoc) {
      details.gstDoc = {
        url: gstDoc.url,
        blobName: gstDoc.type,
      };
    }

    const panDoc = attachments.find((a) => a.type === "PAN_CARD");
    if (panDoc) {
      details.panDoc = {
        url: panDoc.url,
        blobName: panDoc.type,
      };
    }
    const attachment = attachments.find(
      (a) => a.type === VENDOR_DOCUMENT_TYPES.ATTACHMENT
    );
    if (attachment) {
      details.attachment = {
        url: attachment.url,
        blobName: attachment.type,
      };
    }
  }

  return details;
};
