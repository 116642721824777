import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "../../../common-utilities/core/modal";
import {
  HOSTNAME,
  INTRANETHOSTNAME,
} from "../../../common-utilities/utils/end-points";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { getOfflineData } from "../../utils/offline-services";
import { getElectricityTableHeader, getPLTableHeader } from "./helper";
import ClearIcon from "@mui/icons-material/Clear";
import "./index.scss";
import MuiTable from "../../../common-utilities/core/mui-table";
import { tokenMainKeys, appSlugs } from "../../../../utils/constants";
import FileUpload from "../../../common-utilities/core/file-uploader";
import { downloadFileFromUrl } from "../../utils/common-utils";
import { DownloadBtn } from "../../../common-utilities/core/download-btn";
import { convertCamelCaseToDisplayText } from "../../../common-utilities/utils";
import { REST_URLS } from "../../../common-utilities/utils/end-points/electricity-consumption";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DailyReadingsTable from "./daily-reading-table";
import { monthNumberMap } from "../../../common-utilities/utils/constants";
import {
  REGIONS_LIST,
  STORE_TYPE,
} from "../../../store-master/components/store-master/config";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const itemsPerPage = 10;

const ElectricityConsumptionTable = ({
  token = tokenMainKeys.electricityConsumptionTable,
  slug = appSlugs.electricityConsumption,
}) => {
  const userDetails = getOfflineData("user", token) || {};
  const { role = "" } = userDetails;
  const [areaManagers, setAreaManagers] = useState([]);
  const [dateRanges, setDateRanges] = useState({});
  const [data, setData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [searchStore, setSearchStore] = useState("");
  const [addReadingModal, setAddReadingModal] = useState({
    isOpen: false,
    data: {},
  });
  const [readingModal, setReadingModal] = useState({
    isOpen: false,
    data: {},
  });
  const [storeTodaysData, setStoreTodaysData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [todayReading, setTodayReading] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
  });

  // Total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    loadData(filters);
    if (role !== "STORE") {
      loadManager();
    }
  }, []);

  const loadManager = () => {
    invokeApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.AM_USERS}`
    ).then((response) => {
      if (response?.length > 0) {
        setAreaManagers(
          response.map((r) => {
            return {
              ...r,
              label: r.name,
              value: r.id,
            };
          })
        );
      }
    });
  };
  const transformData = (data) => {
    let result = [];

    data.forEach((store) => {
      result = [...result, ...store.entries];
    });

    return result;
  };

  const loadData = (filters, reportType = "json") => {
    let params = { ...filters };
    let payload = {
      dateRange: dateRanges,
    };
    if (reportType) {
      params.reportType = reportType;
    }
    delete params.page;
    invokeApi(
      HTTP_METHODS.POST,
      `${INTRANETHOSTNAME}${REST_URLS.ELECTRICITY_CONSUMPTION_REPORT}`,
      Object.keys(payload.dateRange).length > 0 ? payload : null,
      params
    )
      .then((response) => {
        if (response.message) {
          toast.error(response.message);
        } else {
          if (reportType === "json") {
            const data = transformData(response);

            setData(data);
          } else if (response.url) {
            downloadFileFromUrl(response.url);
          }
        }
      })
      .catch((err) => {});
  };
  const getStoreNumberList = (searchTerm) => {
    let timeoutId = null;
    const storeNo = searchTerm?.split("-")[0];
    clearTimeout(timeoutId);

    const payload = {};

    if (!isNaN(parseInt(storeNo))) {
      payload.storeNumber = storeNo;
    } else if (searchTerm && typeof searchTerm === "string") {
      payload.restaurantName = searchTerm;
    }

    if (Object.keys(payload).length > 0) {
      setTimeout(() => {
        plainApi(
          HTTP_METHODS.GET,
          `${INTRANETHOSTNAME}${REST_URLS.GET_ALL_STORES}`,
          null,
          payload
        ).then((res) => {
          const storeList = res?.results?.map((ele) => ({
            storeNo: ele?.storeNumber,
            satelliteNo: ele?.satelliteNumber,
            sequenceNo: ele?.sequenceNumber,
            restaurantName: ele?.restaurantName,
            id: ele?.id,
          }));
          setStoreList(storeList);
        });
      }, 300);
    }
  };
  const storeInputChange = (e, value) => {
    if (value?.trim()) {
      getStoreNumberList(value);
    }
  };
  const onPageChange = (page) => {
    setFilters({ ...filters, page });
  };
  const viewReadings = (data, type) => {
    switch (type) {
      case "VIEW_READINGS":
        setReadingModal({ isOpen: true, data });
        break;

      default:
        break;
    }
  };
  const getTodaysData = () => {
    plainApi(
      HTTP_METHODS.GET,
      `${INTRANETHOSTNAME}${REST_URLS.CHECK_TODAYS_DATA}`
    )
      .then((res) => {
        if (!res.message) {
          setStoreTodaysData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const updateReadingHandler = (type) => {
    if (type === "start") {
      invokeApi(
        HTTP_METHODS.POST,
        `${INTRANETHOSTNAME}${REST_URLS.ELECTRICITY_CONSUMPTION}`,
        {
          startReading: todayReading,
        }
      ).then((res) => {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success("Reading Updated Successfully");
        }
        setTodayReading("");
        getTodaysData();
        loadData(filters);
      });
    } else if (type === "end") {
      invokeApi(
        HTTP_METHODS.PUT,
        `${INTRANETHOSTNAME}${REST_URLS.ELECTRICITY_CONSUMPTION}`,
        {
          endReading: todayReading,
        }
      ).then((res) => {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success("Reading Updated Successfully");
        }
        setTodayReading("");
        getTodaysData();
        loadData(filters);
      });
    }
  };

  const downloadData = () => {
    loadData(filters, null);
  };
  const currentItems = useMemo(() => {
    const indexOfLastItem = filters.page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return (data || []).slice(indexOfFirstItem, indexOfLastItem);
  }, [filters.page, data]);

  useEffect(() => {
    if (role === "STORE") {
      getTodaysData();
    }
  }, []);
  return (
    <div className="pl-report-container">
      <div className="pl-filter-container">
        {role !== "STORE" && (
          <div className="pl-filter-container">
            <Autocomplete
              getOptionLabel={(option) =>
                option
                  ? `${option.storeNo}-${option.sequenceNo}-${
                      option.satelliteNo
                    }${
                      option.restaurantName ? `-${option.restaurantName}` : ""
                    }`
                  : ""
              }
              className="dropdown-list"
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={() => {
                    if (filters?.store) {
                      delete filters?.store;
                    }
                    setStoreList([]);
                    setSearchStore("");
                  }}
                />
              }
              options={storeList}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Search Store" />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onInputChange={storeInputChange}
              onChange={(event, value) => {
                if (value) {
                  setSearchStore(value?.id);
                }
              }}
            />
            <Autocomplete
              disablePortal
              options={REGIONS_LIST || []}
              className="dropdown-list"
              size="small"
              onChange={(event, values) => {
                if (values) {
                  setFilters({
                    ...filters,
                    region: values.value,
                  });
                } else {
                  delete filters?.region;
                }
              }}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Region" />
              )}
            />
            <Autocomplete
              disablePortal
              options={STORE_TYPE || []}
              className="dropdown-list"
              size="small"
              onChange={(event, values) => {
                if (values) {
                  setFilters({
                    ...filters,
                    type: values.value,
                  });
                } else {
                  delete filters.type;
                }
              }}
              renderInput={(params) => (
                <TextField size="small" {...params} label="Type" />
              )}
            />
            {role !== "AM" && (
              <Autocomplete
                disablePortal
                options={areaManagers || []}
                className="dropdown-list"
                size="small"
                onChange={(event, values) => {
                  if (values) {
                    setFilters({
                      ...filters,
                      am: values.value,
                    });
                  } else {
                    delete filters?.am;
                  }
                }}
                renderInput={(params) => (
                  <TextField size="small" {...params} label="Area Manager" />
                )}
              />
            )}
          </div>
        )}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={"Start Month and Year"}
            views={["month", "year"]}
            minDate={moment("2000-01-01")}
            maxDate={moment("2099-12-31")}
            value={startDate}
            onChange={(value) => {
              if (value) {
                const year = value.year();
                const month = value.month() + 1;

                setDateRanges((prevVal) => ({
                  ...prevVal,
                  startMonth: month,
                  startYear: year,
                }));
                setStartDate(value);
              } else {
                setStartDate(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="new-textfield"
                size="small"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {startDate && (
                        <IconButton
                          onClick={() => {
                            setStartDate(null);
                            delete dateRanges?.startMonth;
                            delete dateRanges?.startYear;
                          }}
                          sx={{ padding: "0px" }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={"End Month and Year"}
            views={["month", "year"]}
            minDate={moment("2000-01-01")}
            maxDate={moment("2099-12-31")}
            value={endDate}
            onChange={(value) => {
              if (value) {
                const year = value.year();
                const month = value.month() + 1;

                setDateRanges((prevVal) => ({
                  ...prevVal,
                  endMonth: month,
                  endYear: year,
                }));
                setEndDate(value);
              } else {
                setEndDate(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="new-textfield"
                size="small"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {endDate && (
                        <IconButton
                          onClick={() => {
                            setEndDate(null);
                            delete dateRanges?.endMonth;
                            delete dateRanges?.endYear;
                          }}
                          sx={{ padding: "0px" }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
        <Button
          variant="outlined"
          onClick={() => {
            if (searchStore) {
              loadData({ ...filters, storeId: searchStore });
            } else {
              loadData({ ...filters });
            }
          }}
        >
          SEARCH
        </Button>
        {currentItems.length > 0 && (
          <DownloadBtn onClick={() => downloadData()} />
        )}

        {role === "STORE" && !storeTodaysData?.startReading && (
          <Button
            variant="outlined"
            onClick={() => setAddReadingModal({ isOpen: true, data: {} })}
          >
            Create New Reading
          </Button>
        )}
        {role === "STORE" &&
          !storeTodaysData?.endReading &&
          storeTodaysData?.startReading && (
            <Button
              variant="outlined"
              onClick={() =>
                setAddReadingModal({ isOpen: true, data: storeTodaysData })
              }
            >
              Create End Reading
            </Button>
          )}
      </div>

      <div className="invoice-table">
        <MuiTable
          columnsList={getElectricityTableHeader() || []}
          dataList={currentItems || []}
          filters={filters}
          pageCount={totalPages}
          onClick={viewReadings}
          onChange={onPageChange}
        />
      </div>
      {readingModal?.isOpen && (
        <CustomModal
          open={readingModal.isOpen}
          title="Daily Readings"
          onClose={() => setReadingModal({ isOpen: false, data: {} })}
        >
          <DailyReadingsTable
            data={readingModal.data.dailyReadings || {}}
            rowData={readingModal.data}
          />
        </CustomModal>
      )}

      {addReadingModal.isOpen && (
        <CustomModal
          open={!!addReadingModal.isOpen}
          title="Enter Today's Reading"
          onClose={() => setAddReadingModal({ isOpen: false, data: {} })}
        >
          {addReadingModal?.data?.startReading ? (
            <TextField
              label="Today End Reading"
              fullWidth
              type="number"
              onChange={(e) => setTodayReading(e?.target?.value)}
              size="small"
            />
          ) : (
            <TextField
              label="Today Start Reading"
              fullWidth
              type="number"
              onChange={(e) => setTodayReading(e?.target?.value)}
              size="small"
            />
          )}

          <Button
            variant="contained"
            fullWidth
            disabled={!todayReading}
            size="small"
            sx={{ mt: 2 }}
            onClick={() => {
              if (
                addReadingModal?.data?.startReading &&
                !addReadingModal?.data?.endReading
              ) {
                updateReadingHandler("end");
                setAddReadingModal({ isOpen: false, data: {} });
              } else if (!addReadingModal?.data?.startReading) {
                updateReadingHandler("start");
                setAddReadingModal({ isOpen: false, data: {} });
              }
            }}
          >
            Save
          </Button>
        </CustomModal>
      )}
    </div>
  );
};

export default ElectricityConsumptionTable;
