import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const DailyReadingsTable = ({ data, rowData }) => {
  if (!data || typeof data !== "object" || Object.keys(data).length === 0) {
    return <p>No data available</p>;
  }

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table size="small">
        <TableHead sx={{ background: "#f3f0f0" }}>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Previous Night Units</TableCell>
            <TableCell>Start Reading</TableCell>
            <TableCell>End Reading</TableCell>
            <TableCell>Difference</TableCell>
            <TableCell>Consumption Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((date) => {
            const reading = data[date];
            return (
              <TableRow key={date}>
                <TableCell>
                  {date} /{rowData.month} /{rowData.year}
                </TableCell>
                <TableCell>{reading?.previousNightUnits ?? "N/A"}</TableCell>
                <TableCell>{reading?.startReading ?? "N/A"}</TableCell>
                <TableCell>{reading?.endReading ?? "N/A"}</TableCell>
                <TableCell>{reading?.difference ?? "N/A"}</TableCell>
                <TableCell>{reading?.consumptionAmount ?? "N/A"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DailyReadingsTable;
